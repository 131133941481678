<template>
	<b-container class="mt-sm-5 mt-3">
		<b-row class="a-section-title">
			<b-col>
				<h2 class="text-center">
					<span class="w-color-secondary z-fancy-underline">
						Naše najpopulárnejšie<svg-fancy-underline-icon />
						<img src="./img/top-courses.png" class="a-small-logo" alt="">
					</span> <span class="w-color-black">video kurzy</span>
				</h2>
			</b-col>
		</b-row>
		<b-row align-h="center">
			<w-loadable :items="topCourses" />
			<template v-if="topCourses && topCourses.length">
				<b-col v-for="(course, i) in topCourses" :key="i" lg="4" md="6" sm="12" class="top-courses-container d-flex justify-content-center">
					<z-course-card v-if="course._model === 'course'" :course="course" />
					<z-path-card v-else :path="course" />
				</b-col>
			</template>
		</b-row>
		<b-row>
			<b-col class="text-center">
				<b-button href="/kurzy" variant="primary" class="a-poppins-btn -medium -wide">Pozrieť všetky kurzy</b-button>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
export default {
	components: {
		'svg-fancy-underline-icon': 	() => import('@/plugins/appzmudri/_theme/icon/fancy-underline.svg?inline'),
		'z-path-card': 								() => import('@/plugins/lib@course/path/card/z-path-card.vue'),
	},
	props: {
		courses: {
			type: Array,
			required: true
		},
		category: {
			type: String,
			required: true
		}
	},
	computed: {
		topCourses() {
			const topCourses = this.courses.filter(course => course.featured && course.featured[this.category])
			topCourses.sort((a, b) => a.featured[this.category] - b.featured[this.category])

			return topCourses.slice(0, 8)

			// NOTE: Old implementation, based on the current count of users that favourited the course.
			// return this.courses && [ ...this.courses ].sort((a, b) => b.stars_count - a.stars_count).slice(0, 8)
		}
	}
}
</script>

<style lang="scss" scoped>
.top-courses-container {
	&:nth-child(7),
	&:nth-child(8) {
		@media only screen and (max-width: 1399px) {
			display: none !important;
		}
	}

	@media only screen and (min-width: 1400px) {
		flex: 0 0 25%;
		max-width: 25%;
	}
}
</style>